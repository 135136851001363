<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item class="pointer" :to="{ path: '/street' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      >
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <div>
              <el-form-item label="地点" prop="loginName">
                <el-input v-model="address"></el-input>
              </el-form-item>
            </div>
            <ul>
              <li class="menu-item text-cut" @click="drawGrid">绘制小区范围</li>

              <li class="menu-item text-cut" @click="closeDrawPolygon">
                结束绘制
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content" id="container"></div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",
      tabs: ["基本信息"],
      street: {
        id: "",
        provinceCode: "",
        provinceName: "",
        districtCode: "",
        districtName: "",
        cityName: "",
        cityCode: "",
        streetCode: "",
        streetName: "",
        loginName: "",
        password: "",
        longitude: "",
        latitude: "",
        path: "",
        memberCode: "",
        memberName: "",
        phone: "",
        userId: "",
        streetRoadCode: "",
      },
      center: [116.397428, 39.90923],
      zoom: 15,
      label: {
        content: "自定义标题",
        offset: [10, 12],
      },
      dialogVisible: false,
      map: {}, //保存地图对象
      mouseTool: {}, // 鼠标多边形绘制工具
      polyType: {
        borderColor: "",
        fillOpacity: 0.5,
        fillColor: "",
      },
      polygonList: [],
      savePolygon: [],
    };
  },
  methods: {
    goIndex() {
      this.$router.push({
        path: "/village",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },
    initMap() {
      this.map = new AMap.Map("container", {
        center: this.center,
        zoom: this.zoom,
      });
      this.resetDraw();
    },

    drawGrid() {
      let that = this;
      this.mouseTool = new AMap.MouseTool(this.map);
      this.mouseTool.polygon({
        strokeColor: this.polyType.borderColor,
        fillColor: this.polyType.fillColor,
        fillOpacity: parseFloat(this.polyType.fillOpacity / 100),
        //同Polygon的Option设置
      });

      //监听draw事件可获取画好的覆盖物
      this.polygonList = [];
      this.mouseTool.on("draw", function (e) {
        console.log(e);
        that.polygonList.push(e.obj);
        console.log("绘制的多边形", that.polygonList);
      });
    },

    closeDrawPolygon() {
      this.mouseTool.close();
      let savePolygon = [];
      for (let i = 0; i < this.polygonList.length; i++) {
        console.log(this.polygonList[i].getOptions());
        let op = this.polygonList[i].getOptions();
        let routePath = [];
        op.path.forEach((item) => {
          routePath.push([item.lng, item.lat]);
        });
        let savearr = {
          path: routePath,
          strokeColor: op.strokeColor,
          strokeOpacity: op.strokeOpacity,
          fillOpacity: op.fillOpacity,
          fillColor: op.fillColor,
          zIndex: op.zIndex,
        };
        this.savePolygon.push(savearr);
      }
      let save = JSON.stringify(this.savePolygon);
      console.log("savePolygon", save);
      //   sessionStorage.setItem("savePolygon", save);
      this.updateStreet(save);
    },

    updateStreet(save) {
      var that = this;
      var data = {
        id: this.$route.query.id,
        path: save,
      };
      that.$http.post("/village/update", data).then(function (response) {
        if (response.data.code == 200) {
          that.$notify.success({
            title: "提示",
            message: "保存成功",
            showClose: true,
          });
        } else {
          that.$notify.error({
            title: "提示",
            message: response.data.message,
            showClose: true,
          });
        }
      });
    },

    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/village/queryOne", { id: this.id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.street = response.data.data;
              that.center = [that.street.longitude, that.street.latitude];
              that.initMap();
            }
          });
      }
    },

    // 用保存的数据重新绘制多边形并添加事件
    resetDraw() {
      let that = this;
      let savePolygon = JSON.parse(that.street.path);
      console.log("重绘多边形数据", savePolygon);
      for (let i = 0; i < savePolygon.length; i++) {
        var polygon = new AMap.Polygon(savePolygon[i]);
        polygon.on("click", function (e) {
          console.log("点击了" + i + "个内容", e);
          that.dialogVisible = true;
        });
        this.map.add(polygon);
      }
    },
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
  },
  mounted() {
    var that = this;
    // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;

    this.loadBaseInfo();
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
</style>
